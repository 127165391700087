import { ModelTypesStore } from "./ModelTypesStore";
import { ModelStore } from "./ModelStore";
import { ImageStore } from "./ImageStore";
import { UserAuthStore } from "./UserAuth";
import { AdminStore, LeaderboardStore } from "./AdminStore";
import { HwkFlowApiClient } from "./Client";
import { MediaStore } from "./MediaStore";

export class AppStore {
    public hwkflowClient: HwkFlowApiClient;
    public modelTypesStore: ModelTypesStore;
    public modelStore: ModelStore;
    public modelArchivedStore: ModelStore;
    public imageStore: ImageStore;
    public userAuthStore: UserAuthStore;
    public adminStore: AdminStore;
    public leaderboardStore: LeaderboardStore;
    public mediaStore: MediaStore;

    constructor(client: HwkFlowApiClient) {
        this.hwkflowClient = client;
        this.modelTypesStore = new ModelTypesStore(this.hwkflowClient);
        this.modelStore = new ModelStore(this.hwkflowClient);
        this.modelArchivedStore = new ModelStore(this.hwkflowClient);
        this.imageStore = new ImageStore(this.hwkflowClient);
        this.userAuthStore = new UserAuthStore(this.hwkflowClient);
        this.adminStore = new AdminStore(this.hwkflowClient);
        this.leaderboardStore = new LeaderboardStore(this.hwkflowClient);
        this.mediaStore = new MediaStore(this.hwkflowClient);
    }
}

export interface ProvidedAppStore {
    store?: AppStore;
}
