import React, { FunctionComponent } from "react";
import { Grid, Menu, Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../store/AppStore";
import { ModelTypes, ModelType } from "../models/ModelType";
import { KeycloakRole } from "../models/User";

import "./Sidebar.css";

@inject("store")
@observer
class SideBar extends React.Component<ProvidedAppStore> {
    async componentDidMount() {
        await this.props.store!.modelTypesStore.fetchModelTypes();
    }

    render() {
        const { modelTypesStore, userAuthStore, hwkflowClient } = this.props.store!;
        const modelTypes = modelTypesStore.modelTypes;

        const typeNames = Object.values(ModelTypes);

        let menuItems: JSX.Element[] | null = null;

        try {
            if (modelTypes?.length > 0) {
                menuItems = typeNames.map((typeName: String, idx: number) => {
                    const menuModelTypes = modelTypes?.filter((modelType: ModelType) => {
                        return ModelTypes[modelType.type] === typeName;
                    });

                    const menuElems = menuModelTypes?.map((modelType: ModelType, idx: number) => {
                        const model_url = `/model/${modelType.name}/summary`;
                        return (
                            <Menu.Item key={idx} as={Link} name={modelType.name} to={model_url} />
                        );
                    });

                    return (
                        <Menu.Item key={idx}>
                            <Menu.Header>{typeName}</Menu.Header>
                            <Menu.Menu>{menuElems}</Menu.Menu>
                        </Menu.Item>
                    );
                });
            }
        } catch (err) {
            console.error("Failed to render tasks list", err);
        }

        return (
            <Menu vertical={true} inverted={true} className="Sidebar">
                <Menu.Item>
                    <Header inverted={true}>HwkFlow</Header>
                </Menu.Item>
                <Menu.Item>
                    <Menu.Header>Overview</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item as={Link} name="Annotation Tasks" to={"/taskboard"} />
                        <Menu.Item as={Link} name="Stats" to={"/statsboard"} />
                        <Menu.Item as={Link} name="admin" to={"/admin/users"} />
                        <Menu.Item as={Link} name="profile" to={"/profile"} />
                        <Menu.Item as={Link} name="report bug" to={"/BugReports/BugReporting"} />
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    <Menu.Header>Media</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item as={Link} name="Explore Media" to={"/media/explore"} />
                    </Menu.Menu>
                </Menu.Item>
                {menuItems}
                <div className="logout_btn">
                    <Button color="teal" onClick={userAuthStore.logOut}>
                        Log out
                    </Button>
                </div>
                {userAuthStore.user?.roles?.includes(KeycloakRole.ADMIN) && (
                    <div>
                        {hwkflowClient.frontendVersion && (
                            <div className="version">
                                <p>Frontend version: {hwkflowClient.frontendVersion}</p>
                            </div>
                        )}
                        {hwkflowClient.backendVersion && (
                            <div className="version">
                                <p>Backend version: {hwkflowClient.backendVersion}</p>
                            </div>
                        )}
                    </div>
                )}
            </Menu>
        );
    }
}

export const SideBarLayout: FunctionComponent = (props) => (
    <Grid>
        <Grid.Row className="App">
            <Grid.Column width={2}>
                <SideBar />
            </Grid.Column>
            <Grid.Column width={14} className="main-container">
                {props.children}
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
