import { observable, action, makeAutoObservable, runInAction } from "mobx";

import { HwkFlowApiClient } from "./Client";
import { Leaderboard, User, UserStatus } from "../models/User";

export interface StatsKey {
    name: string;
    username: string;
    department: string;
    model: string;
    verified: string;
    dateOfCreation: Date;
}

export interface StatsData {
    key: StatsKey;
    count: number;
    totalTime: number;
    avgTime: number;
    verificationTime: number;
    cumulative: number;
}

export interface LeaderboardStats {
    name: string;
    totalAnnotations: number;
    approvalScore: number;
    verified: number;
    declined: number;
    totalTime: number;
    totalAnnotationsToday: number;
    approvalScoreToday: number;
    verifiedToday: number;
    declinedToday: number;
    startOfStreak: Date;
    streak: number;
}

export interface SessionKey {
    name: string;
    department: string;
    dateOfSession: Date;
    length: number;
}

export interface SessionData {
    key: SessionKey;
    count: number;
    totalTime: number;
    avgTime: number;
}

export class AdminStore {
    client: HwkFlowApiClient;
    controller: AbortController = new AbortController();

    constructor(client: HwkFlowApiClient) {
        this.client = client;
        makeAutoObservable(this);
    }

    @observable
    users: User[] = [];

    @observable
    statisticsAnnotations: StatsData[] = [];

    @observable
    statisticsVerifications: StatsData[] = [];

    @observable
    sessionData: SessionData[] = [];

    @observable
    sports: string[] = [];

    @observable
    models: string[] = [];

    @observable
    departments: string[] = [];

    @observable
    activeUsers: string[] = [];

    @action
    public async fetchStatistics() {
        const statsResponse = await this.client.fetchUserMetrics(this.controller.signal);
        const sessionsResponse = await this.client.fetchSessionMetrics(this.controller.signal);
        runInAction(() => {
            this.statisticsAnnotations = statsResponse.data.data.annotations;
            this.statisticsVerifications = statsResponse.data.data.verifications;
            this.sessionData = sessionsResponse.data["data"];
            this.models = statsResponse.data["models"];
            this.departments = statsResponse.data["departments"];
        });
    }

    @action
    public getActiveUsers = async () => {
        const response = await this.client.getActiveUsers(this.controller.signal);
        if (response.status !== 200) {
            console.warn(`failed to update session information!`);
            return;
        }
        this.activeUsers = response.data["activeUsers"];
    };

    @action
    public fetchUsers = async () => {
        const response = await this.client.fetchUsers(this.controller.signal);

        if (response.status !== 200) {
            console.warn(`failed to set name and department!`);
        } else {
            runInAction(() => {
                this.users = response.data as User[];
            });
        }
    };

    @action
    public deactivateUser = async (id: number) => {
        const response = await this.client.changeUserStatus(id, UserStatus.INACTIVE);
        if (response.status !== 200) {
            console.warn(`failed to deactivate user`);
        } else {
            runInAction(() => {
                this.users = this.users.filter(item => item.id !== id);
            });
        }
    };

    @action
    public changeUser = async (user: User) => {
        const response = await this.client.changeUser(user);
        if (response.status !== 200) {
            console.warn(`failed to set name and department!`);
        } else {
            await this.fetchUsers();
        }
    };
}

export class LeaderboardStore {
    client: HwkFlowApiClient;
    controller: AbortController = new AbortController();

    constructor(client: HwkFlowApiClient) {
        this.client = client;
        makeAutoObservable(this);
    }

    @observable
    rawStatistics: LeaderboardStats[] = [];

    @observable
    approvalStatistics: LeaderboardStats[] = [];

    @observable
    annotationStatistics: LeaderboardStats[] = [];

    @observable
    topAnnotations: LeaderboardStats | undefined;

    @observable
    topApproval: LeaderboardStats | undefined;

    @observable
    todayAnnotations: number = 0;

    @observable
    todayApprovalScore: number = 0;

    @observable
    users: User[] = [];

    @observable
    current: Leaderboard | undefined;

    @observable
    previous: Leaderboard | undefined;

    @action
    public async fetchLeaderboardData() {
        const dataResponse = await this.client.fetchLeaderboard(this.controller.signal);
        const leaderboardResponse = await this.client.fetchLeaderboardData(this.controller.signal);
        runInAction(() => {
            this.current = leaderboardResponse.data["current"] as Leaderboard;
            this.previous = leaderboardResponse.data["previous"] as Leaderboard;
            this.rawStatistics = dataResponse.data["rawStats"];
            this.approvalStatistics = dataResponse.data["approvalStats"];
            this.annotationStatistics = dataResponse.data["annotationStats"];
            this.topAnnotations = dataResponse.data["topAnnotation"];
            this.topApproval = dataResponse.data["topApproval"];
            this.todayAnnotations = dataResponse.data["todayAnnotations"];
            this.todayApprovalScore = dataResponse.data["todayApprovalScore"];
        });
    }

    @action
    public fetchUsers = async () => {
        const response = await this.client.fetchUsers(this.controller.signal);

        if (response.status !== 200) {
            console.warn(`failed to set name and department!`);
        } else {
            runInAction(() => {
                this.users = response.data as User[];
            });
        }
    };
}
