import React from "react";
import { inject } from "mobx-react";

import { Header, Grid, Message, Button } from "semantic-ui-react";
import { KeyPointDescription, Instance } from "../../../models/model_types/keypoints";
import { Mode } from "./Annotation";
import { Image } from "./Image";

import { ProvidedAppStore } from "../../../store/AppStore";

interface NewInstanceProps {
    updatedInstances: (instances: Instance[], mode: Mode) => void;
    definition: KeyPointDescription[];
    instances: Instance[];
    color: string;
    bucketPath: string;
}

class State {
    instances: Instance[] = [];
}
type Props = NewInstanceProps & ProvidedAppStore;

@inject("store")
export class NewInstance extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let instances = this.props.instances.slice();
        instances.push({ keypoints: [] });

        this.state = { instances };

        this.onClick = this.onClick.bind(this);
        this.undo = this.undo.bind(this);
    }

    undo() {
        let { instances } = this.state;
        let instance = instances[instances.length - 1];

        instance.keypoints = instance.keypoints.slice(0, -1);
        this.setState({ instances });
    }

    onClick(button: number, x: number, y: number) {
        // visible.. not visible ect....
        let { instances } = this.state;

        let instance = instances[instances.length - 1];

        const definitionIdx = instance.keypoints.length;
        const definition = this.props.definition[definitionIdx];

        instance.keypoints.push({
            string: definition.name,
            pixelX: x,
            pixelY: y,
            visible: button < 2,
            obscured: button === 1,
        });

        if (definitionIdx + 1 === this.props.definition.length) {
            this.props.updatedInstances(instances, Mode.Edit);
        } else {
            this.setState({ instances });
        }
    }

    render() {
        const { bucketPath, definition } = this.props;
        const { instances } = this.state;

        let instance = instances[instances.length - 1];
        const definitionIdx = instance.keypoints.length;

        const keypointDefinition = definition[definitionIdx];

        return (
            <Grid.Row>
                <Grid.Column width={4}>
                    <Header
                        as="h3"
                        content="Create New Instance"
                        subheader="Left click to add a point, right click to skip"
                    />
                    <Message>
                        <Message.Header>Click the {keypointDefinition.name}</Message.Header>
                        <p>{keypointDefinition.description}</p>
                    </Message>
                    <Button labelPosition="left" icon="undo" content="undo" onClick={this.undo} />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Image
                        url={this.props.store!.hwkflowClient.getImageUrl(bucketPath)}
                        groups={[{ color: "pink", instances }]}
                        definition={definition}
                        onClick={this.onClick}
                    />
                </Grid.Column>
            </Grid.Row>
        );
    }
}
