import React from "react";
import { inject } from "mobx-react";
import { AnswerItem, QuestionType, Definition } from "../../../models/model_types/classification";
import {
    Grid,
    Step,
    Segment,
    Icon,
    Button,
    Form,
    Dropdown,
    Table,
    Accordion,
    Label,
    Checkbox,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Image as ImageModel, ImageState } from "../../../models/Image";
import { ModelType } from "../../../models/ModelType";
import { S3Image } from "../../helpers/S3Image";
import { AnnotationInfo, pdfJSX } from "../../helpers/AnnotationInfo";
import { ProvidedAppStore } from "../../../store/AppStore";

enum certaintyLevel {
    Unknown = 0,
    VeryUncertain = 3,
    SomeUncertainty = 5,
    CompletelyCertain = 10,
}

const options = [
    { key: -1, text: "None", value: " " },
    { key: 0, text: "0", value: "0" },
    { key: 1, text: "1", value: "1" },
    { key: 2, text: "2", value: "2" },
    { key: 3, text: "3", value: "3" },
    { key: 4, text: "4", value: "4" },
    { key: 5, text: "5", value: "5" },
    { key: 6, text: "6", value: "6" },
    { key: 7, text: "7", value: "7" },
    { key: 8, text: "8", value: "8" },
    { key: 9, text: "9", value: "9" },
];

const defaultAnswer = new Map<number, AnswerItem>([
    [0, { type: QuestionType.String, string: "No Annotation " }],
    [1, { type: QuestionType.String, string: "No Annotation " }],
    [2, { type: QuestionType.String, string: "No Annotation " }],
]);

interface AnnotationProps {
    image: ImageModel;
    modelType: ModelType;
    taskId?: string;
    verification: boolean;
    onSubmit: (
        discard: boolean,
        answer: any,
        imageState: ImageState,
        isAnnotationUpdate: boolean
    ) => void;
    onVerify: (approved: boolean, comment: string, discarded: boolean) => void;
}

interface State {
    currentAnswer: Map<number, AnswerItem>;
    certainty: certaintyLevel;
    referee: boolean;
    activeTags: boolean;
}
type Props = AnnotationProps & ProvidedAppStore;
@inject("store")
export class Annotation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let currentAnswer = new Map(defaultAnswer);
        let referee = false;
        const activeTags = false;
        let certainty = certaintyLevel.Unknown;

        this.state = { currentAnswer, certainty, referee, activeTags };
        this.updateCurrentAnswerItem = this.updateCurrentAnswerItem.bind(this);
    }

    componentDidUpdate(previousProps: Props) {
        if (previousProps !== this.props) {
            let currentAnswer = new Map(defaultAnswer);
            let referee = false;
            let certainty = certaintyLevel.Unknown;

            this.setState({ currentAnswer, certainty, referee });
        }
    }

    updateCurrentAnswerItem(item: Map<number, AnswerItem>) {
        this.setState({ currentAnswer: item });
    }

    updateFirstDigit(item: AnswerItem) {
        this.setState({ currentAnswer: this.state.currentAnswer.set(0, item) });
    }
    updateSecondDigit(item: AnswerItem) {
        this.setState({ currentAnswer: this.state.currentAnswer.set(1, item) });
    }
    updateThirdDigit(item: AnswerItem) {
        this.setState({ currentAnswer: this.state.currentAnswer.set(2, item) });
    }

    getSortedAnswer() {
        // note(jack.edney) - This is because dataset contains images with answers to old classification question which aren`t used any more
        const defaultQ1: AnswerItem = {
            type: QuestionType.Boolean,
            bool: false,
        };
        const defaultQ2: AnswerItem = { type: QuestionType.Choice, choice: 0 };

        let certaintyNum: number = this.state.certainty;
        const referee: AnswerItem = {
            type: QuestionType.Boolean,
            bool: this.state.referee,
        };
        const certainty: AnswerItem = {
            type: QuestionType.Choice,
            choice: certaintyNum,
        };

        let sortedAnswer: AnswerItem[] = [
            defaultQ1,
            defaultQ2,
            this.convertDropdownAnswer(this.state.currentAnswer),
            referee,
            certainty,
        ];
        return sortedAnswer;
    }

    convertDropdownAnswer(items: Map<number, AnswerItem>) {
        let answerString =
            items.get(0)!.string!.trim() +
            items.get(1)!.string!.trim() +
            items.get(2)!.string!.trim();

        return {
            type: QuestionType["String"],
            string: answerString,
        } as AnswerItem;
    }

    private get allowSubmit() {
        let threeDigitsPresent =
            this.state.currentAnswer.get(0)!.string !== "No Annotation " &&
            this.state.currentAnswer.get(1)!.string !== "No Annotation " &&
            this.state.currentAnswer.get(2)!.string !== "No Annotation ";

        return threeDigitsPresent && this.state.certainty > certaintyLevel.Unknown;
    }

    render() {
        const { questions } = this.props.modelType.definition as Definition;
        const { image, modelType } = this.props;
        const { activeTags } = this.state;
        const verification = this.props.verification;
        const questionsList = (
            <Step active={true} completed={this.allowSubmit}>
                <Icon name="pencil" />
                <Step.Content>
                    <Step.Title>Annotation</Step.Title>
                    <Step.Description>{questions[2].question}</Step.Description>
                </Step.Content>
            </Step>
        );

        const ann = image.annotations[image.annotations.length - 1];
        let annComment = "";
        if (image.annotations.length > 0) {
            annComment = ann.comment;
        }

        let textAreaJSX = <div />;
        // Checking if there is a comment on the annotation
        if (!!annComment) {
            textAreaJSX = (
                <Segment>
                    <b>Decline Comment:</b>
                    <Segment>{annComment}</Segment>
                </Segment>
            );
        }

        let redirectPath = verification
            ? `/model/${modelType.name}/verification`
            : `/model/${modelType.name}/annotation`;
        if (this.props.taskId != null) {
            redirectPath = verification
                ? `/model/${modelType.name}/verification/task/${this.props.taskId}`
                : `/model/${modelType.name}/annotation/task/${this.props.taskId}`;
        }

        let timeFormat = { day: "numeric", month: "long", year: "numeric" };

        const setActiveTags = (activeTags: boolean) => {
            this.setState({ activeTags: activeTags });
        };

        const setCertainty = (certainty: certaintyLevel) => {
            this.setState({ certainty: certainty });
        };

        const setReferee = (referee: boolean) => {
            this.setState({ referee: referee });
        };

        const tagItems = image.tags.map((tag) => (
            <Label key={tag.name} style={{ "margin-bottom": "7px" }}>
                {tag.name}
            </Label>
        ));

        let tagSegmentJSX = <div />;
        if (tagItems.length !== 0) {
            tagSegmentJSX = (
                <Accordion styled={true} fluid={true}>
                    <Accordion.Title
                        active={activeTags}
                        index={0}
                        onClick={() => {
                            setActiveTags(!activeTags);
                        }}
                    >
                        <Icon name="dropdown" />
                        Tags
                    </Accordion.Title>
                    <Accordion.Content active={activeTags}>{tagItems}</Accordion.Content>
                </Accordion>
            );
        }

        let buttonsJSX = (
            <Segment>
                <Button
                    color="green"
                    disabled={!this.allowSubmit}
                    content={`Submit Annotation`}
                    onClick={() =>
                        this.props.onSubmit(
                            false,
                            this.getSortedAnswer(),
                            ImageState.NeedsVerification,
                            false
                        )
                    }
                />
                <Button
                    content="Discard image"
                    labelPosition="left"
                    icon="trash"
                    color="red"
                    onClick={() =>
                        this.props.onSubmit(
                            true,
                            this.getSortedAnswer(),
                            ImageState.NeedsVerification,
                            false
                        )
                    }
                />
            </Segment>
        );
        let answerJSX = <div />;

        if (verification) {
            buttonsJSX = (
                <AnnotationInfo
                    tall
                    disabled={!this.allowSubmit}
                    approveButton={true}
                    ann={ann}
                    answer={this.getSortedAnswer()}
                    onVerify={this.props.onVerify}
                    onSubmit={this.props.onSubmit}
                />
            );
            if (ann) {
                const answer = ann.answerData;
                const icon = answer[3] ? (
                    answer[3].bool ? (
                        <Icon color="green" name="checkmark" size="large" />
                    ) : (
                        <Icon color="red" name="times" size="large" />
                    )
                ) : (
                    "Unknown"
                );
                const certainty = answer[4] ? certaintyLevel[answer[4].choice] : "Unknown";
                answerJSX = (
                    <>
                        <Table.Row>
                            <Table.Cell>Annotation:</Table.Cell>
                            <Table.Cell>
                                {answer[2]
                                    ? answer[2].string === ""
                                        ? "No Numbers"
                                        : answer[2].string
                                    : "Not annotated"}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Certainty:</Table.Cell>
                            <Table.Cell>{certainty}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Referee:</Table.Cell>
                            <Table.Cell>{icon}</Table.Cell>
                        </Table.Row>
                    </>
                );
            }
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={7}>
                        <Segment>
                            <S3Image
                                size="huge"
                                url={this.props.store!.hwkflowClient.getImageUrl(
                                    this.props.image.bucketPath
                                )}
                            />
                            <Segment>
                                {textAreaJSX}
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Image</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                {this.props.image.id}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>Sport:</Table.Cell>
                                            <Table.Cell>{this.props.image.sport}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Stadium:</Table.Cell>
                                            <Table.Cell>{this.props.image.stadium}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Date Uploaded:</Table.Cell>
                                            <Table.Cell>
                                                {new Date(
                                                    this.props.image.uploadedAt
                                                ).toLocaleString("en-US", timeFormat)}
                                            </Table.Cell>
                                        </Table.Row>
                                        {answerJSX}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={7} padded>
                        <Grid.Row style={{ overflowY: 'auto' }}>
                            {pdfJSX(this.props.modelType)}
                        </Grid.Row>
                        <Grid.Row>
                            {tagSegmentJSX}
                        </Grid.Row>
                        <Grid.Row>
                            <Step.Group fluid size="small">
                                {questionsList}
                            </Step.Group>
                        </Grid.Row>
                        <Grid.Row>
                            <Segment>
                                <Form onSubmit={() => this.setState({})}>
                                    <Form.Group widths="equal">
                                        <Dropdown
                                            compact={true}
                                            fluid={true}
                                            onChange={(e: any, value: any) => {
                                                this.updateFirstDigit({
                                                    type: QuestionType.String,
                                                    string: value.value,
                                                });
                                            }}
                                            options={options}
                                            placeholder="First digit"
                                            selection
                                            value={this.state.currentAnswer.get(0)!.string!}
                                        />
                                        <Dropdown
                                            compact={true}
                                            fluid={true}
                                            wrapSelection={true}
                                            onChange={(e: any, value: any) => {
                                                this.updateSecondDigit({
                                                    type: QuestionType.String,
                                                    string: value.value,
                                                });
                                            }}
                                            options={options}
                                            placeholder="Second digit"
                                            selection
                                            value={this.state.currentAnswer.get(1)!.string!}
                                        />
                                        <Dropdown
                                            compact={true}
                                            fluid={true}
                                            wrapSelection={true}
                                            onChange={(e: any, value: any) => {
                                                this.updateThirdDigit({
                                                    type: QuestionType.String,
                                                    string: value.value,
                                                });
                                            }}
                                            options={options}
                                            placeholder="Third digit"
                                            selection
                                            value={this.state.currentAnswer.get(2)!.string!}
                                        />
                                    </Form.Group>
                                </Form>
                                <Segment secondary>
                                    Current value:{" "}
                                    {this.convertDropdownAnswer(this.state.currentAnswer).string}
                                </Segment>
                            </Segment>
                        </Grid.Row>
                        <Grid.Row>
                            <Segment>
                                <Form>
                                    <Form.Field>
                                        <b>How certain are you with your annotation?</b>
                                    </Form.Field>
                                    <Form.Group inline>
                                        <Form.Radio
                                            label="Completely Certain"
                                            checked={
                                                this.state.certainty ===
                                                certaintyLevel.CompletelyCertain
                                            }
                                            onChange={(e, data) =>
                                                setCertainty(certaintyLevel.CompletelyCertain)
                                            }
                                        />
                                        <Form.Radio
                                            label="Some Uncertainty"
                                            checked={
                                                this.state.certainty ===
                                                certaintyLevel.SomeUncertainty
                                            }
                                            onChange={(e, data) =>
                                                setCertainty(certaintyLevel.SomeUncertainty)
                                            }
                                        />
                                        <Form.Radio
                                            label="Very Uncertain"
                                            checked={
                                                this.state.certainty ===
                                                certaintyLevel.VeryUncertain
                                            }
                                            onChange={(e, data) =>
                                                setCertainty(certaintyLevel.VeryUncertain)
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Field>
                                        <b>Is this a referee jersey?</b>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox
                                            label="Referee"
                                            checked={this.state.referee === true}
                                            onChange={(e, data) => setReferee(!this.state.referee)}
                                        />
                                    </Form.Field>
                                </Form>
                            </Segment>
                        </Grid.Row>
                        <Grid.Row>
                            <Segment>
                                <Link to={redirectPath}>{buttonsJSX}</Link>
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
