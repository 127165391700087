import Axios from "axios";
import { Image, ImageState, ImageStateUse } from "../models/Image";
import { User, UserStatus } from "../models/User";
import { ReportType } from "../models/Bug";
import { TaskPriority } from "../models/Task";
import { TrainingState } from "../models/Training";
import { TOKEN_KEY } from "../constants/auth";
import { FilterState, MediaUpdateRequest } from "./MediaStore";

export class HwkFlowApiClient {
    constructor(
        private baseUrl: string,
        public frontendVersion?: string,
        public backendVersion?: string
    ) {}

    public getImageUrl = (url: string) => {
        if (!url) return "";
        const uri = new URL(`/data/fetch?path=${url}`, this.baseUrl);
        const token = window.localStorage.getItem(TOKEN_KEY);

        if (token) {
            uri.searchParams.set("token", token);
        }
        return uri.toString();
    };

    // Models

    public submitAnnotation = async (
        imageId: number,
        answer: any,
        completionTime: number,
        comment: string,
        taskId: any,
        imageState: ImageState,
        discard: boolean,
        user: User | undefined,
        update: boolean,
        verification?: boolean
    ) => {
        let url = update ? `/models/update-annotation` : `/models/submit-annotation`;
        return Axios.post(this.baseUrl + url, {
            imageId: imageId,
            answer: answer,
            completionTime: completionTime,
            comment: comment,
            taskId: taskId,
            imageState: imageState,
            discard: discard,
            user: user,
            verification,
        });
    };

    public verifyAnnotation = async (
        imageId: number,
        taskId: any,
        verified: boolean,
        comment: string,
        discarded: boolean,
        verificationTime: number
    ) => {
        return Axios.post(this.baseUrl + `/models/verify-annotation`, {
            imageId: imageId,
            taskId: taskId,
            verified: verified,
            comment: comment,
            discarded: discarded,
            verificationTime: verificationTime,
        });
    };

    public moveImages = async (
        model_name: string,
        add_sports: string[],
        add_stadiums: string[],
        tags: string[],
        applications: string[],
        image_states: string[],
        info: any
    ) => {
        return Axios.post(this.baseUrl + `/models/${model_name}/move-images`, {
            conditions: {
                add_sports: add_sports,
                add_stadiums: add_stadiums,
                tags: tags,
                applications: applications,
                imageStates: image_states,
            },
            info: info,
        });
    };

    public discoverImages = async (model_name: string) => {
        return Axios.post(this.baseUrl + `/models/${model_name}/discover-images`);
    };

    public pruneImages = async (model_name: string) => {
        return Axios.post(this.baseUrl + `/models/${model_name}/prune-images`);
    };

    public fetchModelType = async (model_name: string) => {
        return Axios.get(this.baseUrl + `/models/${model_name}/info`);
    };

    public fetchModelImages = async (task: string, imageState: any, filterState: any) => {
        return Axios.post(this.baseUrl + `/models/${task}/${imageState}/images`, filterState);
    };

    public fetchAnnotation = (
        task: string,
        type: string,
        taskId: number,
        imageId: number,
        signal: AbortSignal
    ) => {
        return Axios.get(this.baseUrl + `/models/${task}/${type}`, {
            params: { image: imageId, task: taskId },
            signal: signal,
        });
    };

    public fetchTypes = async () => {
        return Axios.get(this.baseUrl + `/models/types`);
    };

    public seedTypes = async (allow_update: boolean) => {
        return Axios.post(this.baseUrl + `/models/seed-types`, {
            allow_update: allow_update,
        });
    };

    //  Colors requests
    public fetchColors = async () => {
        return Axios.get(this.baseUrl + `/colors`);
    };

    public addColor = async (newColor: string) => {
        return Axios.post(this.baseUrl + `/colors`, { name: newColor });
    };

    // Team Metrics requests
    public fetchKitsByAttributes = async (
        sport: string,
        jerseyColors: string[],
        shortColor: string[],
        socksColor: string[]
    ) => {
        return Axios.post(this.baseUrl + `/team-metrics/get-kits-by-attrs`, {
            sport: sport as string,
            jerseyColors: jerseyColors,
            shortColor: shortColor,
            socksColor: socksColor,
        });
    };

    public fetchKitById = async (teamId: number) => {
        return Axios.post(this.baseUrl + `/team-metrics/get-kits-by-id`, { teamId: teamId });
    };

    public checkAndUpdateKit = async (
        teamId: number,
        jerseyColors: string[],
        shortColor: string[],
        socksColor: string[]
    ) => {
        return Axios.post(this.baseUrl + `/team-metrics/check-update-kits`, {
            teamId: teamId,
            jerseyColors: jerseyColors,
            shortColor: shortColor,
            socksColor: socksColor,
        });
    };

    public addKit = async (
        sport: string,
        jerseyColors: string[],
        shortColor: string[],
        socksColor: string[]
    ) => {
        return Axios.post(this.baseUrl + `/team-metrics/add-kit`, {
            sport: sport as string,
            jerseyColors: jerseyColors,
            shortColor: shortColor,
            socksColor: socksColor,
        });
    };

    // Team metrics requests.
    public addTeamMetrics = async (teamId: number, imageId: number) => {
        return Axios.post(this.baseUrl + `/team-metrics/add-team-metrics`, {
            teamId: teamId,
            imageId: imageId,
        });
    };

    public fetchTeamMetricsById = async (teamId: number) => {
        return Axios.post(this.baseUrl + `/team-metrics/get-team-metrics-by-id`, {
            teamId: teamId,
        });
    };

    public deleteTeamMetricsByImageId = async (imageId: number) => {
        return Axios.post(this.baseUrl + `/team-metrics/delete-team-metrics-by-imageId`, {
            imageId: imageId,
        });
    };

    // Tasks
    public submitTask = async (
        description: string,
        instructions: string,
        model: string,
        target: number,
        deadline: Date,
        sports: string[],
        stadiums: string[],
        applications: string[],
        priority: TaskPriority,
        tags: string[],
        projects: string[],
        earliestUploadDate?: Date,
        latestUploadDate?: Date
    ) => {
        return Axios.post(this.baseUrl + `/tasks/submit-task`, {
            description: description,
            instructions: instructions,
            model: model,
            target: target,
            deadline: deadline,
            sports: sports,
            stadiums: stadiums,
            applications: applications,
            priority: priority,
            tags: tags,
            projects: projects,
            earliestUploadDate: earliestUploadDate,
            latestUploadDate: latestUploadDate,
        });
    };

    public editTask = async (
        id: number,
        description: string,
        instructions: string,
        model: string,
        target: number,
        deadline: Date,
        sports: string[],
        stadiums: string[],
        applications: string[],
        priority: TaskPriority,
        failureTags: string[],
        projects: string[],
        earliestUploadDate?: Date,
        latestUploadDate?: Date
    ) => {
        return Axios.post(this.baseUrl + `/tasks/set-task-properties`, {
            id: id,
            description: description,
            instructions: instructions,
            model: model,
            target: target,
            deadline: deadline,
            sports: sports,
            stadiums: stadiums,
            applications: applications,
            priority: priority,
            failureTags: failureTags,
            projects: projects,
            earliestUploadDate: earliestUploadDate,
            latestUploadDate: latestUploadDate,
        });
    };

    public topUpTask = async (id: number, sourceState: ImageState) => {
        return Axios.post(this.baseUrl + `/tasks/top-up-task`, { taskId: id, sourceState });
    };

    public setTaskState = async (id: number, state: any) => {
        return Axios.post(this.baseUrl + `/tasks/set-task-properties`, {
            id: id,
            state: state,
        });
    };

    public setTaskTag = (id: number, tags: string[]) => {
        return Axios.post(this.baseUrl + `/tasks/set-task-properties`, {
            id: id,
            tags: tags,
        });
    };

    public setProjectID = async (id: number, clearMLTaskID: any) => {
        return Axios.post(this.baseUrl + `/tasks/set-project-properties`, {
            id: id,
            clearMLTaskID: clearMLTaskID,
        });
    };

    public setProjectHwkNumber = async (id: number, hwkNumber: any) => {
        return Axios.post(this.baseUrl + `/tasks/set-project-properties`, {
            id: id,
            hwkNumber: hwkNumber,
        });
    };

    public fetchTasks = (updateState: boolean, signal: AbortSignal) => {
        let url = `/tasks/fetch-tasks`;
        if (updateState) {
            url = `/tasks/fetch-and-update-tasks`;
        }
        return Axios.get(this.baseUrl + url, {
            signal: signal,
        });
    };

    public fetchProjects = (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/tasks/fetch-projects`, {
            signal: signal,
        });
    };

    public addProject = async (name: string, projectId: string) => {
        return Axios.post(this.baseUrl + `/tasks/add-project`, {
            name: name,
            id: projectId,
        });
    };

    // Bug reporting

    public fetchBugs = (params: any, signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/bug_reporting/fetchBugReports`, {
            params: params,
            signal: signal,
        });
    };

    public submitReport = async (report: string, reportType: ReportType) => {
        return Axios.post(this.baseUrl + `/bug_reporting/submitBugReport`, {
            report: report,
            reportType: reportType,
        });
    };

    public upvoteBug = async (id: number) => {
        return Axios.post(this.baseUrl + `/bug_reporting/upvoteBug`, {
            id: id,
        });
    };

    public deleteBug = async (id: number) => {
        return Axios.post(this.baseUrl + `/bug_reporting/deleteBug`, {
            id: id,
        });
    };

    // Images

    public fetchImages = (image_ids: number[], with_relations: boolean) => {
        return Axios.post(this.baseUrl + `/images/fetch-by-ids`, {
            image_ids: image_ids,
            with_relations: with_relations,
        });
    };

    public updateImage = (image: Image) => {
        return Axios.post(this.baseUrl + `/images/set-properties`, image);
    };

    public updateTag = (id: number, tags: { name: any }[]) => {
        return Axios.post(this.baseUrl + `/images/set-properties`, {
            id: id,
            tags: tags,
        });
    };

    public addTag = async (name: string) => {
        return Axios.post(this.baseUrl + `/tags`, {
            name: name,
        });
    };

    public removeTag = async (name: string) => {
        return Axios.delete(this.baseUrl + `/tags`, {
            data: { name: name },
        });
    };

    public fetchTags = async (signal?: AbortSignal, modelId?: string, sports?: string[]) => {
        return Axios.get(this.baseUrl + `/tags`, {
            signal: signal,
            params: {
                model: modelId,
                sports: sports,
            },
        });
    };

    // Files

    public fetchFiles = (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/files/fetch-files`, {
            signal: signal,
        });
    };

    public discoverFiles = async () => {
        return Axios.post(this.baseUrl + `/files/discover-files`);
    };

    public updateDescription = async (id: number, description: string) => {
        return Axios.post(this.baseUrl + `/files/update-description`, {
            id: id,
            description: description,
        });
    };

    // Trainings

    public fetchTrainings = (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/trainings/fetch-trainings`, {
            signal: signal,
        });
    };

    public fetchQueueInfo = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/trainings/fetch-queue-info`, {
            signal: signal,
        });
    };

    public setTrainingState = async (id: number, state: TrainingState) => {
        return Axios.post(this.baseUrl + `/trainings/retrain`, {
            id: id,
            state: state,
        });
    };

    public train = async (projectIds: number[]) => {
        return Axios.post(this.baseUrl + `/trainings/train`, {
            projectIDs: projectIds,
        });
    };

    public retrain = async (trainingID: number) => {
        return Axios.post(this.baseUrl + `/trainings/retrain`, {
            trainingId: trainingID,
        });
    };

    // Metrics

    public fetchUserMetrics = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/metrics/users`, {
            signal: signal,
        });
    };

    public fetchSessionMetrics = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/metrics/sessions`, {
            signal: signal,
        });
    };

    public fetchModelStatistics = async (
        model: string,
        imageState: ImageStateUse,
        signal: AbortSignal
    ) => {
        return Axios.get(this.baseUrl + `/metrics/${model}/${imageState}/histogram/images`, {
            signal: signal,
        });
    };

    public getActiveUsers = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/metrics/get-active-users`, {
            signal: signal,
        });
    };

    public fetchLeaderboard = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/metrics/leaderboard`, {
            signal: signal,
        });
    };

    public fetchLeaderboardData = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/metrics/get-leaderboard`, {
            signal: signal,
        });
    };

    public newLeaderboard = async (startDate: any, endDate: any) => {
        return Axios.post(this.baseUrl + `/metrics/new-leaderboard`, {
            start: startDate,
            end: endDate,
        });
    };

    // Users

    public fetchCurrentUser = async () => {
        return Axios.post(this.baseUrl + `/users/get-current-user`, {
            auto: true,
        });
    };

    public setCurrentUser = async (name: string, department: string) => {
        return Axios.post(this.baseUrl + `/users/set-current-user`, {
            name: name,
            department: department,
        });
    };

    public fetchUsers = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/users/all`, {
            signal: signal,
        });
    };

    public changeUser = async (user: User) => {
        return Axios.post(this.baseUrl + `/users/change-user`, user);
    };

    public changeUserStatus = async (id: number, status: UserStatus) => {
        return Axios.post(`${this.baseUrl}/users/change-status/${id}`, { status });
    };

    public logOut = async (signal: AbortSignal) => {
        return Axios.get(this.baseUrl + `/auth/logout`, {
            signal: signal,
        });
    };

    // Sports

    public fetchAllSports = async () => {
        return Axios.get(this.baseUrl + `/sports`);
    };

    // Media

    public fetchMediaFilterOptions = () => {
        return Axios.get(this.baseUrl + `/media/filter-options`);
    };

    public fetchMediaFilterTags = (sports: string[]) => {
        return Axios.get(this.baseUrl + "/tags/media", {
            params: {
                sports: sports,
            },
        });
    };

    public fetchFilteredMediaIdsAndCount = (filterConditions: FilterState) => {
        return Axios.post(this.baseUrl + `/media/find`, filterConditions);
    };

    public fetchMediaByIds = (mediaIds: number[], withRelations: boolean) => {
        return Axios.post(this.baseUrl + `/media/fetch-by-ids`, {
            ids: mediaIds,
            with_relations: withRelations,
        });
    };

    public updateMedia = (media: MediaUpdateRequest) => {
        return Axios.post(this.baseUrl + `/media/set-properties`, {
            ...media,
        });
    };

    public importMediaAnnotationTypeFiles = (allowUpdate?: boolean) => {
        return Axios.post(this.baseUrl + `/media/annotation-types/import-files`, {
            allowUpdate: allowUpdate,
        });
    };

    public fetchMediaAnnotationTypes = () => {
        return Axios.get(this.baseUrl + `/media/annotation-types/`);
    };
}
