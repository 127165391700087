import React from 'react';
import { inject } from "mobx-react";
import { Answer, AnswerItem, Definition } from "../../../models/model_types/metric";
import {
    Grid,
    Step,
    Segment,
    Icon,
    Button,
    Form,
    Table,
    Accordion,
    Label,
    Select,
    Input,
    Divider,
    Modal,
    Image, 
    Message
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Image as ImageModel, ImageState } from "../../../models/Image";
import { ModelType } from "../../../models/ModelType";
import { AnnotationInfo, pdfJSX } from "../../helpers/AnnotationInfo";
import { ProvidedAppStore } from "../../../store/AppStore";
import { TeamMetricsKits } from '../../../models/TeamMetrics';


interface AnnotationProps {
    image: ImageModel;
    modelType: ModelType;
    taskId?: string;
    verification: boolean;
    onSubmit: (
        discard: boolean,
        answer: any,
        imageState: ImageState,
        isAnnotationUpdate: boolean,
    ) => void;
    onVerify: (approved: boolean, comment: string, discarded: boolean) => void;
}

interface State {
    jersey: string[];
    short: string[];
    socks: string[];
    activeTags: boolean;
    colors: string[];
    addColor: string;
    sameColorWarning: boolean;
    enableComparison: boolean;
    enableNewKit: boolean;
    urlsDict: Map<number, string[]>;
    selectedImages: Map<number, string>;
    clickedImage: any;
    approve: boolean; 
    crossVerification: boolean;
    refreshView: number; 
    teamKitVerification: TeamMetricsKits | null;
}

type Props = AnnotationProps & ProvidedAppStore;
@inject("store")
export class Annotation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let jersey = [] as string[];
        let short = [] as string[];
        let socks = [] as string[];
        const activeTags = false;
        let colors = [] as string[];
        let addColor = "";
        const sameColorWarning = false;
        const enableComparison = false;
        const enableNewKit = false; 
        let urlsDict= new Map(); 
        let selectedImages = new Map(); 
        let clickedImage = null;
        let approve = false;
        let crossVerification = false; 
        let refreshView = 0;
        let teamKitVerification = null;

        this.state = { jersey, short, socks, activeTags, colors, addColor, sameColorWarning, enableComparison, enableNewKit, urlsDict, selectedImages, clickedImage, approve, crossVerification, refreshView, teamKitVerification};
    }

    async verificationInit(){
        const { annotations } = this.props.image;
        this.setState({crossVerification:false})
        if (annotations.length !== 0) {
            const lastAnnotation = annotations[annotations.length - 1];
            const answer = lastAnnotation.answerData as Answer;
            const kitId = answer[0].teamId;
            if(kitId !== undefined){
                let kit = kitId !== undefined ? await this.props.store?.imageStore.getKitByTeamId(kitId) : null; 
                this.setState({teamKitVerification: kit});
                this.handleVerification(kitId);
                this.setState({crossVerification:false})
            }else{
                this.setState({crossVerification:true, approve:true})
            }
        }
    }

    async componentDidMount() {
        // Fetch and sort colors when component mounts so we are able to add the color options in the dropdown. 
        this.props.store?.imageStore.getColors().then((fetchedColors) => {
            const sortedColors = fetchedColors.slice().sort();
            this.setState({colors: sortedColors})
            // Add not visible option. 
            this.setState(prevState => ({
                colors: [...prevState.colors, "not visible"]
            }))
        }).catch((error) => {
            console.error("Error fetching colors", error)
        })
            
        // Run the verification logic if we are in the verification process. 
        if (this.props.verification){
            this.verificationInit()
        }
    }


    async componentDidUpdate(previousProps: Props) {
        if (previousProps !== this.props) {

            if (this.props.verification){
                this.verificationInit()
            }else{
                const sameColorWarning = false;
                const enableComparison = false;
                const enableNewKit = false; 
                let approve = false;
                let crossVerification = false; 
                let jersey = [] as string[];
                let short = [] as string[];
                let socks = [] as string[];  
                this.setState({jersey, short, socks, sameColorWarning, enableComparison, enableNewKit, approve, crossVerification})
                this.setState(prevState => ({refreshView: prevState.refreshView +1 }))
            }
        }
    }

    /* This is the data that will be stored in the answerData column of the annotation table.
    Currently we only need the teamId.*/
    getSortedAnswer(id: number | undefined) {
        const teamId: AnswerItem = {teamId: id,};
        let sortedAnswer: AnswerItem[] = [teamId];
        return sortedAnswer;
    }

    // We will only be able to create a new team or go to the comparison step if the kit colors are annotated. 
    private get allowSubmit() {
        let annotationsPresent = this.state.jersey.length >0 && this.state.short.length >0 && this.state.socks.length >0 && !this.state.sameColorWarning; 
        return annotationsPresent;       
    }

    /* Function that adds a new kit to the database if create kit is true. 
    Else the annotator has backed out, and will reannotate the kit colors*/
    handleKitCreation= async (createKit: boolean ) => {
        if (createKit) { 
            const sport = this.props.image.sport; 
            const {jersey, short, socks} = this.state;

            let newKit = await this.props.store?.imageStore.addNewKit(sport, jersey, short, socks);
            await this.props.store?.imageStore.addNewTeamMetrics(newKit.id, this.props.image.id);
            this.props.onSubmit(false, this.getSortedAnswer(newKit.id), ImageState.NeedsVerification, false);
            // New kit has been created so we don't need the comparison step. 
            this.setState({enableComparison:false});
        }   
        this.setState({enableNewKit: false});
    }

    setUrlsDict = async (matchingKits: number[]) => {
        // urls -> keys: teamId, values: image paths of the samples that belong to a team
        let urls = new Map<number, string[]>();
        for (let idx = matchingKits.length-1; idx>=0; idx--){
            let kitId = matchingKits[idx];
            /* matchingTeams will return TeamMetrics for data whose state is NeedsVerification, Verified or data sent to reannotation,
             in the later case, we need to make show that we don't show the current image as a reference image. */
            let matchingTeams = await this.props.store?.imageStore.getTeamMetricsById(kitId);
            const filterUrls = matchingTeams.filter((item: {image: {bucketPath: string; imageState: ImageState;};}) => {
                return !this.props.image.bucketPath.includes(item.image.bucketPath)})
                .map((item: {image: {bucketPath: string;};}) => item.image.bucketPath);
            if (filterUrls.length !== 0){urls.set(kitId, filterUrls);}               
        }
        this.setState({urlsDict: urls});
        for(let kitId of matchingKits){
            this.selectRandomImage(kitId)
        }
    }

    // Function that will allow us to go from the initial step (color annotation) to the comparison step and viceversa. 
    allowComparisonStep = async () => { 
        const {sport} = this.props.image; 
        const {jersey, short, socks} = this.state;
        
        const exisitingKits = await this.props.store?.imageStore.getKitsByAttributes(sport, jersey.sort(), short, socks);
        /* If a kit with the given attributes doesn't exist, we enableNewKit so we can add a new kit to the database. 
        Else, we allow the comparison step where reference images will be shown*/
        if (exisitingKits.length === 0) {
            this.setState({enableNewKit: true});
        }else{
            this.setUrlsDict(exisitingKits);
            this.setState({enableComparison:true});
        }
    }

    // For a given key, we will select a random image to use as referece. 
    selectRandomImage = (key: any) => {
        const { urlsDict } = this.state;
        const urls = urlsDict.get(key);
        if (urls && urls.length > 0) {
          const randomIndex = Math.floor(Math.random() * urls.length);
          const selectedImage = this.props.store!.hwkflowClient.getImageUrl(urls[randomIndex]);
          this.setState(prevState => ({selectedImages: new Map(prevState.selectedImages.set(key, selectedImage))}));
        }
    }

 
    // Function to handle typing new color
    handleInputColor = (event: { target: { value: any; }; }) => {
        this.setState({addColor: event.target.value.toLowerCase()});
    }

    // Function to handle setting a new color
    handleSetColor = () => {
        const {addColor, colors} = this.state
        if (addColor.trim() !== ''){
            let msg = ''
            if (addColor.length > 2 && !colors.includes(addColor)){
                msg = 'Are you sure you want to add ' + addColor.toUpperCase() +' as a color? Please check that the spelling is correct. If you are unsure please ask someone from the team';
            }else if (addColor.length <3){ 
                msg = 'The color you typed does not exist';
            } else{
                msg = ' The color you typed already exists!';
            }
            
            const newColorConfirmation = window.confirm(msg);
            // Add new color to the database if the user confirms he is sure. 
            if (newColorConfirmation && addColor.length > 2 && !this.state.colors.includes(addColor)){
                this.setState(prevState => ({ colors: [...prevState.colors, addColor]}));
                this.props.store?.imageStore.addNewColor(addColor);
            }
            this.setState({addColor: ''});
        }}

    
    // Function that deals with the logic when the annotated image matches a team form the database. 
    handleMatchedReference =  async (index: number) => {
        const {jersey, short, socks} = this.state;
        // If the kit contains a non visible color, we will infer the color from the current annotation
        await this.props.store?.imageStore.checkKitUpdate(index, jersey, short, socks)
        // Add the team metric annotation to the database. 
        await this.props.store?.imageStore.addNewTeamMetrics(index, this.props.image.id)
        this.props.onSubmit(false, this.getSortedAnswer(index), ImageState.NeedsVerification, false);
        this.setState({enableComparison:false, enableNewKit: false});   
    };
    
    // Function to handle the verification step
    handleVerification = async (annTeamId: number) => {
        // If the image has not been discarded, the verifier will start with the comparison step.
        if(annTeamId >= 0){
            let annTeam = await this.props.store?.imageStore.getKitByTeamId(annTeamId);
            let  matchingKits = await this.props.store?.imageStore.getKitsByAttributes(annTeam.sport, annTeam.jerseyColors, annTeam.shortColor, annTeam.socksColor);
            await this.setUrlsDict(matchingKits); 
            if (this.state.urlsDict.size === 0 ) {   
                this.setState({crossVerification:true, approve: true})}

        }else{
            this.setState({crossVerification:true, approve: true})
        }
    }

    // Function to handle the verification comparison step.
    handleVerificationComparison =  async (verTeamId: number) => {
        const annTeamId = this.props.image.annotations[0].answerData[0].teamId;

        // If the annotation and verification matches, we can approve the annotation
        if (annTeamId === verTeamId || (verTeamId === -1 && !this.state.urlsDict.has(annTeamId))){
            this.setState({approve:true, crossVerification: true})
        }else{
            this.setState({approve:false, crossVerification: true})
        }
    };

    // Function to handle discarded images.
    handleDiscard = async () =>{
        const imageId = this.props.image.id;
        await this.props.store?.imageStore.removeTeamMetricsByImageId(imageId);
        this.props.onSubmit(true, this.getSortedAnswer(undefined), ImageState.NeedsVerification, false)
    }


    render() {
        const { questions } = this.props.modelType.definition as Definition;
        const { image, modelType } = this.props;
        const { activeTags} = this.state;
        const { colors, addColor, refreshView} = this.state;
        const { jersey, short, socks} = this.state;
        const { selectedImages, clickedImage, urlsDict} = this.state;
        const { enableComparison, enableNewKit, sameColorWarning, crossVerification, approve} = this.state;
        const verification = this.props.verification;
        
        const questionsList = (
            <Step active={true} completed={this.allowSubmit}>
                <Icon name="pencil" />
                <Step.Content>
                    <Step.Title>Annotation</Step.Title>
                </Step.Content>
            </Step>
        );

        const ann = image.annotations[image.annotations.length - 1];
        let annComment = "";
        if (image.annotations.length > 0) {
            annComment = ann.comment;
        }

        let textAreaJSX = <div />;
        // Checking if there is a comment on the annotation
        if (!!annComment) {
            textAreaJSX = (
                <Segment>
                    <b>Decline Comment:</b>
                    <Segment>{annComment}</Segment>
                </Segment>
            );
        }

        let redirectPath = verification
            ? `/model/${modelType.name}/verification`
            : `/model/${modelType.name}/annotation`;
        if (this.props.taskId != null) {
            redirectPath = verification
                ? `/model/${modelType.name}/verification/task/${this.props.taskId}`
                : `/model/${modelType.name}/annotation/task/${this.props.taskId}`;
        }

        let timeFormat = { day: "numeric", month: "long", year: "numeric" };

        const setActiveTags = (activeTags: boolean) => {this.setState({ activeTags: activeTags });};

        const setPrimaryJersey = (color: string[]) => {
            if (color[0] === 'not visible' || jersey.length < 2 ){
                this.setState({jersey: color, sameColorWarning : false});
            } else if (jersey[1] !== color[0]){
                this.setState({jersey: [...color, jersey[1]], sameColorWarning: false});
            }else{
                this.setState({jersey: [...color, jersey[1]], sameColorWarning: true})                
            }
        };

        const setSecondaryJersey = (color: string[]) => {
            const primaryJersey = [jersey[0]];
            if (primaryJersey[0] !== color[0] && color[0] !== "None"){
                this.setState({jersey : [...primaryJersey, ...color], sameColorWarning:false}) 
            }else if (color[0] !== "None"){
                this.setState({jersey : [...primaryJersey, ...color], sameColorWarning:true})
            }else{
                this.setState({jersey: primaryJersey, sameColorWarning: false})
            } 
        };

        const setShort = (short: string[]) => {this.setState({ short: short });};
        const setSocks = (socks: string[]) => {this.setState({ socks: socks });};
        // Function to handle image click
        const augmentImage = (index: any) => {this.setState({ clickedImage: index });};
       
        let buttonsJSX = <div />;
        let answerJSX = <div />;

        if (verification && crossVerification) {   
            buttonsJSX = (
                <div>
                    {!approve ? (<Message negative>
                            <Message.Header>UNMATCHED VERIFICATION </Message.Header>
                            <p>The selected team by the verifier and the annotator does not match.</p>
                            <p>You can only discard or decline the annotation</p>
                    </Message>) : null}
                    {this.state.urlsDict.size === 0 && !ann.discarded? (<Message positive>
                            <Message.Header>NEW KIT </Message.Header>
                            <p>No reference image for this kit</p>
                            <p>This image will be verified with a teamId: <b>{ann.answerData[0].teamId}</b></p>
                    </Message>) : approve && !ann.discarded?(<Message positive>
                            <Message.Header>NEW TEAM METRICS DATA </Message.Header>
                            <p>This image will be verified with a teamId: <b>{ann.answerData[0].teamId}</b></p>
                    </Message>): null}

                    <AnnotationInfo tall ann={ann} approveButton={approve}
                    answer={this.getSortedAnswer(ann.answerData[0].teamId)}
                    onVerify={this.props.onVerify}
                    onSubmit={this.props.onSubmit}
                /></div>
            );
            if (ann) {
                const answer = ann.answerData;
                const {teamKitVerification} = this.state;

                answerJSX = (
                    <>
                        <Table.Row>
                            <Table.Cell>Team id :</Table.Cell>
                            <Table.Cell>{answer[0].teamId}</Table.Cell>
                        </Table.Row>  

                        {teamKitVerification && ( 
                            <><Table.Row>
                                <Table.Cell>Jersey Color :</Table.Cell>
                                <Table.Cell>{teamKitVerification.jerseyColors.join(', ')}</Table.Cell>
                            </Table.Row><Table.Row>
                                    <Table.Cell>Short Color :</Table.Cell>
                                    <Table.Cell>{teamKitVerification.shortColor}</Table.Cell>
                                </Table.Row><Table.Row>
                                    <Table.Cell>Socks Color:</Table.Cell>
                                    <Table.Cell>{teamKitVerification.socksColor}</Table.Cell>
                                </Table.Row></>
                        )}
                    </>
                );
            }
        }


        const tagItems = image.tags.map((tag) => (
            <Label key={tag.name} style={{ "margin-bottom": "7px" }}>{tag.name}</Label>
        ));
        
        
        let tagSegmentJSX = <div />;
        if (tagItems.length !== 0) {
            tagSegmentJSX = (
                <Accordion styled={true} fluid={true}>
                    <Accordion.Title
                        active={activeTags}
                        index={0}
                        onClick={() => {
                            setActiveTags(!activeTags);
                        }}
                    >
                        <Icon name="dropdown" />
                        Tags
                    </Accordion.Title>
                    <Accordion.Content active={activeTags}>{tagItems}</Accordion.Content>
                </Accordion>
            );
        }
        
        // Set attributes.
        const setAttributesJSX = (setLabelFn: (arg0: string[]) => void) => {
            const options = setLabelFn === setSecondaryJersey ? 
                [{key: 'None', text: "None", value: "None" },
                 ...colors.map((value, index)=> ({ key: index, text: value,value: value,}))]: 

                colors.map((value, index)=> ({key: index, text: value,value: value,})) 
            if (colors.length !== 0) {
                return (
                        <Select 
                            placeholder='Select colour'
                            fluid
                            key = {refreshView}
                            selection
                            search
                            options={options}
                            onChange={(event, data) => {
                                if (data && data.value) {
                                    setLabelFn([data.value] as string[]); }
                            }}
                        />   
                );}

        } 

        
        const comparisonJSX =(
            <div><Segment>
                <Grid columns={2} doubling stackable>
                    <Step.Group fluid>
                        <Step active={true}>
                            <Icon name="mouse pointer" />
                            <Step.Content>
                                <Step.Title>Select team</Step.Title>
                            </Step.Content>
                        </Step>
                    </Step.Group>
                    {Array.from(urlsDict.entries()).map(([key, urlsVal]) => (
                        <Grid.Column key={key}>
                            <Image style={{ width: 'auto', height: "220px" }}                                        
                        src={selectedImages.get(key)} // Display the selected image if available
                        />
                        <Button icon='zoom' color='teal' onClick={() => augmentImage(selectedImages.get(key))}/>
                        {urlsVal.length>1 ? <Button icon='refresh' onClick={() => this.selectRandomImage(key)}/>: null}
                        <Link to={redirectPath}><Button icon='check' color='green' onClick={async () => verification ? this.handleVerificationComparison(key) : this.handleMatchedReference(key) }/></Link>
                        </Grid.Column>
                    ))}
                    <Modal open={clickedImage !== null}>
                    <Modal.Header><Button icon='close' floated='right' onClick={() => this.setState({clickedImage: null})}/></Modal.Header>
                    <Modal.Content image>
                        <Image
                        src={clickedImage !== null ? clickedImage : null}
                        alt={"Clicked image"}
                        size='big'
                        />
                    </Modal.Content>
                    </Modal>
                </Grid>
                </Segment> 
                <Link to={redirectPath}><Button icon='plus circle' color='blue' content="New kit" onClick={  () => verification ? this.handleVerificationComparison(-1): this.handleKitCreation(true)}/></Link>                           
                <Link to={redirectPath}><Button  content="Discard image"  labelPosition="left" icon="trash" color="red"
                onClick={this.handleDiscard}/></Link>
            </div>
        ) 


        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Segment><Image style={{width: 'auto', height: "580px"}} src={this.props.store!.hwkflowClient.getImageUrl(this.props.image.bucketPath)}/>
                            <Segment>
                                {textAreaJSX}
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Image</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                {image.id}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>Sport:</Table.Cell>
                                            <Table.Cell>{image.sport}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Stadium:</Table.Cell>
                                            <Table.Cell>{image.stadium}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>Date Uploaded:</Table.Cell>
                                            <Table.Cell>
                                                {new Date(image.uploadedAt).toLocaleString("en-US", timeFormat)}
                                            </Table.Cell>
                                        </Table.Row>
                                        {answerJSX}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Segment>
                    </Grid.Column>

                    {/*1st annotation step: kit color annotation.*/}
                    <div>
                        {!enableComparison && !verification && (<div> {
                            <Grid.Column width={7}>
                                <Grid.Row> <Segment>{pdfJSX(this.props.modelType)}</Segment></Grid.Row>
                                <Grid.Row>{tagSegmentJSX}</Grid.Row>
                                <Grid.Row> <Step.Group fluid size="small">{questionsList}</Step.Group> </Grid.Row>

                                <Grid.Row>
                                    <Segment>
                                        <Form>
                                            <Form.Field>
                                                <label> {questions[0].question}</label>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <label>{"Primary color"}</label>
                                                    {setAttributesJSX(setPrimaryJersey)}
                                                </div>
                                                {jersey.length>0 && jersey[0] !== "not visible" ? <div>
                                                    <label>Secondary color: only for striped or checked jerseys.</label>
                                                    <p>If not stripped or checked please select <b>None</b></p>
                                                    {setAttributesJSX(setSecondaryJersey)}
                                                </div>: null}
                                                {sameColorWarning && <div style={{'color': 'red'}}>Secondary color cannot be the same as the primary one</div>}                                        
                                                
                                            </Form.Field>
                                            <Divider section />
                                            <Form.Field>
                                                <b>{questions[1].question}</b>
                                                {setAttributesJSX(setShort)}
                                            </Form.Field>
                                            <Divider section />
                                            <Form.Field>
                                                <b>{questions[2].question}</b>
                                                {setAttributesJSX(setSocks)}
                                            </Form.Field>
                                            <Divider section />
                                            <Input
                                                type="text"
                                                value={addColor}
                                                onChange={this.handleInputColor}
                                                placeholder = "Type new color:"
                                            />  
                                            <Button
                                                color='yellow'
                                                icon = "plus circle"
                                                content={`Add new color`}
                                                onClick={this.handleSetColor}
                                            />
                                            <Button
                                                color="green"
                                                floated='right'
                                                icon = "check circle"
                                                disabled={!this.allowSubmit}
                                                content={`Next`}
                                                onClick={this.allowComparisonStep}
                                            />   

                                            <Modal open={enableNewKit}>
                                                <Modal.Header>No data matching given annotation</Modal.Header>
                                                <Modal.Content>
                                                    <p>A new team will be added to the dataset</p>
                                                    <p>This {image.sport} team will have the following properties:</p>
                                                    <ul>
                                                        <li>Jersey color: {jersey.map((item, index) => {
                                                            if (item!=null){ return item.concat(" ")}
                                                        })}</li>
                                                        <li>Short color: {short}</li>
                                                        <li>Socks color: {socks}</li>
                                                    </ul>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Link to={redirectPath}><Button color='green' content='Create and submit' icon='plus circle' onClick={() =>this.handleKitCreation(true)}/></Link>
                                                    <Button color='red' content="Cancel" labelPosition="left" icon="cancel"  onClick={() => this.handleKitCreation(false)}/>
                                                </Modal.Actions>
                                            </Modal>                             
                                        </Form>
                                    </Segment>    
                                </Grid.Row>
                                <Link to={redirectPath}><Button  content="Discard image"  labelPosition="left" icon="trash" color="red" onClick={this.handleDiscard}/></Link>
                            </Grid.Column>  
                        }</div>
                    )} </div>

                    {/*2nd annotation step: compare with reference images.*/}
                    <div>{this.state.enableComparison && (<div> {
                        <Grid.Column width={7}>
                            <Button
                                color="grey"
                                icon = "backward"
                                content={`Back`}
                                onClick={() => this.setState({enableComparison:false, jersey: [] as string[], short: [] as string[], socks: [] as string[]})}
                            />
                            <Grid.Row>
                            {comparisonJSX}
                            </Grid.Row>
                        </Grid.Column>
                    }</div>)} </div>

                    {/*1st verfication step: compare with reference images*/}
                    <div>{verification && !crossVerification  && (<div> {
                        <Grid.Column >
                            <Grid.Row>
                            {urlsDict.size > 0 ? comparisonJSX: null}
                            
                            </Grid.Row>
                        </Grid.Column>
                    }</div>)}</div>

                    {/*2nd verfication step: decide to approve or not*/}
                    <div>{verification && crossVerification  && (<div> {
                        <Grid.Column>{buttonsJSX}</Grid.Column>
                    }</div>)}</div>
    
                </Grid.Row>
            </Grid>
        );
    }
}
