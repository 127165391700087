export enum NavigationDirection {
    Next,
    Previous,
}

export function findNewId(
    ids: number[],
    currentId: number,
    direction: NavigationDirection
): number | undefined {
    let currentIndex: number = ids.indexOf(currentId);
    if (currentIndex < 0) return;

    let nextIndex: number;
    if (direction == NavigationDirection.Next) {
        nextIndex = currentIndex + 1;
    } else {
        nextIndex = currentIndex - 1;
    }
    nextIndex = Math.abs(nextIndex % ids.length);
    return ids[nextIndex];
}
