import React from "react";
import { inject, observer } from "mobx-react";
import { Header, Message, Divider, Icon } from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";

import { ProvidedAppStore } from "../../store/AppStore";
import { Loading } from "../helpers/Loading";
import { Image, ImageState } from "../../models/Image";
import { ModelTypes } from "../../models/ModelType";

import { Annotation as KeyPointAnnotation } from "./KeyPoint/Annotation";
import { Annotation as ImageClassificationAnnotation } from "./ImageClassification/Annotation";
import { Annotation as ObjectDetectionAnnotation } from "./ObjectDetection/Annotation";
import { Annotation as MetricAnnotation } from "./Metric/Annotation";
import { AbortRequests } from "../helpers/AbortRequests";

type ToastStatus = 'none' | 'annotationSuccess' | 'discardSuccess' | 'failure';

interface RouteParams {
    imageId: string;
    sport: string;
    stadium: string;
    taskId: string;
}

interface Props {
    verification: boolean;
    task: any;
    imageId?: any;
    taskId?: any;
}

type AnnotationProps = ProvidedAppStore & Props;

interface AnnotationState {
    image?: Image;
    taskId?: string;
    instructions: string;
    sport: string;
    stadium: string;
    tags: string[];
    then: number;
    toastStatus: ToastStatus;
    isToastVisible: boolean;
}

export class AnnotationWizard extends React.Component<RouteComponentProps<RouteParams>> {
    render() {
        const { params } = this.props.match;
        const { task } = params as any;

        let imageId = undefined;
        let taskId = undefined;
        if ("imageId" in params) {
            imageId = params.imageId;
        }
        if ("taskId" in params) {
            taskId = params.taskId;
        }

        return <Annotation verification={false} task={task} imageId={imageId} taskId={taskId} />;
    }
}

export class VerificationWizard extends React.Component<RouteComponentProps<RouteParams>> {
    render() {
        const { params } = this.props.match;
        const { task } = params as any;

        let imageId = undefined;
        let taskId = undefined;
        if ("imageId" in params) {
            imageId = params.imageId;
        }
        if ("taskId" in params) {
            taskId = params.taskId;
        }

        return <Annotation verification={true} task={task} imageId={imageId} taskId={taskId} />;
    }
}

@inject("store")
@observer
export class Annotation
    extends React.Component<AnnotationProps, AnnotationState>
    implements AbortRequests {
    controller: AbortController = new AbortController();
    mounted: boolean = false;
    private toastTimeout: NodeJS.Timeout | null = null;
    constructor(props: AnnotationProps) {
        super(props);
        this.state = {
            image: undefined,
            sport: "All",
            stadium: "All",
            taskId: undefined,
            tags: [],
            instructions: "",
            then: Date.now(),
            toastStatus: 'none',
            isToastVisible: false,
        };

        this.fetchAnnotation = this.fetchAnnotation.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.verifyAnnotation = this.verifyAnnotation.bind(this);
    }

    showToast(status: ToastStatus): Promise<void> {
        return new Promise((resolve) => {
            this.setState({ toastStatus: status, isToastVisible: true });
            this.toastTimeout = setTimeout(() => {
                this.setState({ isToastVisible: false });
                resolve();
            }, 2000);
        });
    }

    async fetchAnnotation() {
        const task = this.props.task;

        const type = this.props.verification ? "verification" : "annotation";

        // Flag to indicate that the image is not specificied, i.e. fetch random image
        let imageId = undefined;

        if (this.props.imageId) {
            imageId = this.props.imageId;
        }
        if (this.props.taskId) {
            this.setState({ taskId: this.props.taskId });
        }
        this.props
            .store!.hwkflowClient.fetchAnnotation(
                task,
                type,
                this.props.taskId,
                imageId,
                this.controller.signal
            )
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ image: undefined });
                    return;
                }

                const data = response.data;
                this.setState({
                    image: data["image"] as Image,
                    instructions: data["instructions"],
                    then: Date.now(),
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async submitAnswer(
        discard: boolean,
        answer: any,
        imageState: ImageState,
        isAnnotationUpdate: boolean
    ) {
        const now = Date.now();
        let completionTime = now - this.state.then;

        const { image } = this.state;
        if (image == null) {
            return;
        }

        const annot = image.annotations[image.annotations.length - 1];
        let user = undefined;
        let annotComment = "";
        if (annot !== undefined) {
            annotComment = !annot.comment ? "" : annot.comment;
        }

        const response = await this.props.store!.hwkflowClient.submitAnnotation(
            image.id,
            answer,
            completionTime,
            annotComment,
            this.state.taskId!,
            imageState,
            discard,
            user,
            isAnnotationUpdate,
            this.props.verification
        );

        if (response.status !== 200) {
            alert(`answer submit failed! ${response.status} - ${response.statusText}`);
            await this.showToast('failure');
        } else {
            await this.showToast(discard ? 'discardSuccess' : 'annotationSuccess');
        }
        if (this.mounted) await this.fetchAnnotation();
    }

    async verifyAnnotation(approved: boolean, comment: string, discarded: boolean) {
        const now = Date.now();
        let verificationTime = now - this.state.then;

        const { image } = this.state;
        if (image == null) {
            return;
        }

        const response = await this.props.store!.hwkflowClient.verifyAnnotation(
            image.id,
            this.state.taskId!,
            approved,
            comment,
            discarded,
            (verificationTime = verificationTime)
        );
        if (response.status !== 200) {
            alert(`failed to verify annotation - ${response.status} - ${response.statusText}`);
            return;
        }

        if (this.mounted) await this.fetchAnnotation();
    }

    async componentDidMount() {
        this.mounted = true;
        await this.fetchAnnotation();
    }

    async componentWillUnmount() {
        if (this.toastTimeout) {
            clearTimeout(this.toastTimeout);
        }
        this.mounted = false;
        this.controller.abort();

    }

    renderToast() {
        if (!this.state.isToastVisible) return null;

        switch (this.state.toastStatus) {
            case 'annotationSuccess':
                return <Message positive>Annotation submitted successfully!</Message>;
            case 'discardSuccess':
                return <Message positive>Image has been discarded successfully!</Message>;
            case 'failure':
                return <Message negative>Annotation or image discard has failed!</Message>;
            default:
                return null;
        }
    }

    render() {
        const modelType = this.props.store!.modelStore.modelType;
        const { image, instructions } = this.state;
        const verification = this.props.verification;

        let past = verification ? "verified" : "annotated";
        let future = verification ? "verify" : "annotate";
        let present = verification ? "verification" : "annotation";

        if (modelType == null) {
            return <Loading />;
        }

        let annotationJSX = <div />;

        if (image == null) {
            annotationJSX = (
                <Message
                    success
                    header={`Model data has been ${past}!`}
                    content={`All images selected for ${present} have been completed! check for images to ${future}`}
                />
            );
        } else if (modelType.type === ModelTypes.KeyPoints) {
            annotationJSX = (
                <KeyPointAnnotation
                    image={image}
                    modelType={modelType}
                    taskId={this.state.taskId}
                    verification={verification}
                    onSubmit={this.submitAnswer}
                    onVerify={this.verifyAnnotation}
                />
            );
        } else if (modelType.type === ModelTypes.Classification) {
            annotationJSX = (
                <ImageClassificationAnnotation
                    image={image}
                    modelType={modelType}
                    taskId={this.state.taskId}
                    verification={verification}
                    onSubmit={this.submitAnswer}
                    onVerify={this.verifyAnnotation}
                />
            );
        } else if (modelType.type === ModelTypes.ObjectDetection) {
            annotationJSX = (
                <ObjectDetectionAnnotation
                    image={image}
                    modelType={modelType}
                    taskId={this.state.taskId}
                    verification={verification}
                    onSubmit={this.submitAnswer}
                    onVerify={this.verifyAnnotation}
                />
            );
        } else if (modelType.type === ModelTypes.Metric) {
            annotationJSX = (
                <MetricAnnotation
                    image={image}
                    modelType={modelType}
                    taskId={this.state.taskId}
                    verification={verification}
                    onSubmit={this.submitAnswer}
                    onVerify={this.verifyAnnotation}
                />
            );
        } else {
            annotationJSX = (
                <Message
                    negative
                    header="Unknown Model Type"
                    content="This type of annotation type is not supported"
                />
            );
        }

        let imageJSX = <div />;
        let discardedJSX = <div />;
        if (image) {
            if (image.annotations.length > 0) {
                const ann = image.annotations[image.annotations.length - 1];
                if (ann.discarded && verification) {
                    discardedJSX = (

                        <Message negative>
                            <Message.Header>
                            <Icon name="attention"></Icon>
                            DISCARDED - Image will be moved to Unused on approval
                        </Message.Header>
                        </Message>
                    );
                }
            }

            imageJSX = <Header as="h2" content={`${present} for Image ${image.id}`} />;
        }

        let instructionsJSX = <div />;
        if (instructions.length > 0) {
            instructionsJSX =(
                <Message
                icon
                style={{
                  backgroundColor: '#E9F4F4',
                  borderRadius: '8px',
                  border: '1px solid #D6E8E8',
                  color: '#2D9C9C',
                }}
              >
                <Icon name='info circle' color='teal' />
                <Message.Content>
                  <Message.Header>Task Instructions</Message.Header>
                    {instructions}
                </Message.Content>
              </Message>
            );
        }

        return this.state.isToastVisible ? (<> {this.renderToast()} </>) : (
            <>
                <Divider hidden={true} />
                {imageJSX}
                {discardedJSX}
                {instructionsJSX}
                <Divider hidden={true} />
                {annotationJSX}
            </>
        );
    }
}
